exports.components = {
  "component---src-pages-2018-04-14-ieltscoachingfee-index-js": () => import("./../../../src/pages/2018/04/14/ieltscoachingfee/index.js" /* webpackChunkName: "component---src-pages-2018-04-14-ieltscoachingfee-index-js" */),
  "component---src-pages-2019-10-18-goethe-exam-schedules-index-js": () => import("./../../../src/pages/2019/10/18/goethe-exam-schedules/index.js" /* webpackChunkName: "component---src-pages-2019-10-18-goethe-exam-schedules-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-clc-manjeri-index-js": () => import("./../../../src/pages/clc-manjeri/index.js" /* webpackChunkName: "component---src-pages-clc-manjeri-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-german-language-courses-german-class-in-manjeri-js": () => import("./../../../src/pages/german-language-courses/german-class-in-manjeri.js" /* webpackChunkName: "component---src-pages-german-language-courses-german-class-in-manjeri-js" */),
  "component---src-pages-german-language-courses-index-js": () => import("./../../../src/pages/german-language-courses/index.js" /* webpackChunkName: "component---src-pages-german-language-courses-index-js" */),
  "component---src-pages-how-to-prepare-for-ielts-without-coaching-at-home-js": () => import("./../../../src/pages/how-to-prepare-for-ielts-without-coaching-at-home.js" /* webpackChunkName: "component---src-pages-how-to-prepare-for-ielts-without-coaching-at-home-js" */),
  "component---src-pages-ielts-ielts-coaching-in-manjeri-js": () => import("./../../../src/pages/ielts/ielts-coaching-in-manjeri.js" /* webpackChunkName: "component---src-pages-ielts-ielts-coaching-in-manjeri-js" */),
  "component---src-pages-ielts-ielts-results-js": () => import("./../../../src/pages/ielts/ielts-results.js" /* webpackChunkName: "component---src-pages-ielts-ielts-results-js" */),
  "component---src-pages-ielts-index-js": () => import("./../../../src/pages/ielts/index.js" /* webpackChunkName: "component---src-pages-ielts-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oet-index-js": () => import("./../../../src/pages/OET/index.js" /* webpackChunkName: "component---src-pages-oet-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacypolicy-index-js": () => import("./../../../src/pages/privacypolicy/index.js" /* webpackChunkName: "component---src-pages-privacypolicy-index-js" */),
  "component---src-pages-pte-index-js": () => import("./../../../src/pages/pte/index.js" /* webpackChunkName: "component---src-pages-pte-index-js" */),
  "component---src-pages-spoken-english-best-spoken-english-class-in-manjeri-js": () => import("./../../../src/pages/spoken-english/best-spoken-english-class-in-manjeri.js" /* webpackChunkName: "component---src-pages-spoken-english-best-spoken-english-class-in-manjeri-js" */),
  "component---src-pages-spoken-english-index-js": () => import("./../../../src/pages/spoken-english/index.js" /* webpackChunkName: "component---src-pages-spoken-english-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

